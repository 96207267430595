<template>
  <div class="pageContol listWrap templateList classCheckDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >结算单管理</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">（财务）复核</a>
        </span>
      </div>
      <div class="framePage-body ovy-a">
        <h3 style="padding-bottom: 20px">结算确认单</h3>
        <div style="display: flex;font-size: 18px;padding: 0 0 20px 0;">
          <span  style="margin:5px 0;">合同附件:</span>
          <div style="display: flex;flex-direction: column;">
             <span v-for="(item, index) in annexList" :key="index"  style="margin:5px 0;">
          {{index+1}}.{{item.fileName}} <span style="padding-left:15px;color:#4089fa;cursor: pointer;" @click="lookFile(item.fileUrl)">预览</span></span>
          </div>
         
        </div>
        <div>
          <iframe
            style="width: 50%; height: 500px"
            :src="pdfUrl"
            frameborder="0"
          ></iframe>
        </div>
        <!-- <div class="el-div"></div> -->
        <!-- <h3 style="padding-bottom:20px;">{{this.$route.query.stu =='collection'?'班级明细':'课程明细'}}</h3> -->
        <!-- <div v-if="this.$route.query.stu =='collection'">
            <iframe style="width:50%;height:500px" :src="pdfUrl1" frameborder="0"></iframe>
        </div>
        <div v-else>
           <iframe style="width:50%;height:500px" :src="pdfUrl1" frameborder="0"></iframe>
        </div> -->
        <div class="el-div" v-show="stu != 'agency'"></div>
        <h3 style="padding-bottom: 20px" v-show="stu != 'agency'">班级明细</h3>
        <div v-show="stu != 'agency'">
          <el-table
            ref="multipleTable"
            :data="ListData2"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
            />
            <el-table-column
              label="班级名称"
              align="left"
              show-overflow-tooltip
              prop="projectName"
              min-width="260px"
            />
            <el-table-column
              label="班级编码"
              align="left"
              show-overflow-tooltip
              prop="projectCode"
              min-width="180px"
            />
            <el-table-column
              label="机构名称"
              align="left"
              show-overflow-tooltip
              prop="compName"
              min-width="260px"
            />
            <el-table-column
              label="行政区划"
              align="left"
              show-overflow-tooltip
              prop="areaName"
              min-width="150px"
            />
            <el-table-column
              label="培训类型"
              align="left"
              show-overflow-tooltip
              prop="trainTypeName"
              min-width="150px"
            />
            <el-table-column
              label="岗位类型"
              align="left"
              show-overflow-tooltip
              prop="postName"
              min-width="150px"
            />
            <el-table-column
              label="行业类型"
              align="left"
              show-overflow-tooltip
              prop="industryName"
              min-width="150px"
            />
            <el-table-column
              label="培训工种"
              align="left"
              show-overflow-tooltip
              prop="occupationName"
              min-width="150px"
            />
            <el-table-column
              label="级别"
              align="left"
              show-overflow-tooltip
              prop="trainLevelName"
              min-width="150px"
            />
            <el-table-column
              label="开班时间"
              align="left"
              show-overflow-tooltip
              prop="startDate"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{ scope.row.startDate | momentDate }}
              </template>
            </el-table-column>
            <el-table-column
              label="结束时间"
              align="left"
              show-overflow-tooltip
              prop="endDate"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{ scope.row.endDate | momentDate }}
              </template>
            </el-table-column>
            <el-table-column
              label="班级状态"
              align="left"
              show-overflow-tooltip
              prop="trainState"
              min-width="150px"
            />
            <el-table-column
              label="收款结算单编号"
              align="left"
              show-overflow-tooltip
              prop="billNo"
              min-width="200px"
            />
            <el-table-column
              label="收款结算单状态"
              align="left"
              show-overflow-tooltip
              prop="billState"
              min-width="150px"
            />
            <el-table-column
              label="国家补贴标准"
              align="left"
              show-overflow-tooltip
              prop="countrySubsidyStandard"
              min-width="150px"
            />
            <el-table-column
              label="应收平台技术服务费"
              align="left"
              show-overflow-tooltip
              prop="platformTechnicalFee"
              min-width="150px"
            />
            <el-table-column
              label="应收课程服务费"
              align="left"
              show-overflow-tooltip
              prop="courseFee"
              min-width="150px"
            />
            <el-table-column
              label="应收平台服务费"
              align="left"
              show-overflow-tooltip
              prop="platformTotalFee"
              min-width="150px"
            />
            <el-table-column
              label="平台服务总费用"
              align="left"
              show-overflow-tooltip
              prop="payerAmount"
              min-width="150px"
            />
            <el-table-column
              label="折后金额"
              align="left"
              show-overflow-tooltip
              prop="settlementAmount"
              min-width="150px"
            />
            <el-table-column
              label="二次结算金额"
              align="left"
              show-overflow-tooltip
              prop="secondFee"
              min-width="150px"
            />
            <el-table-column
              label="收款结算人数"
              align="left"
              show-overflow-tooltip
              prop="settleNum"
              min-width="150px"
            />
            <el-table-column
              label="收款结算单到账状态"
              align="left"
              show-overflow-tooltip
              prop="arrival"
              min-width="150px"
            >
              <template slot-scope="scope">
                {{
                  scope.row.arrival == "10"
                    ? "未到账"
                    : scope.row.arrival == "20"
                    ? "已到账"
                    : scope.row.arrival == "15"
                    ? "部分到账"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="课程总学时"
              align="left"
              show-overflow-tooltip
              prop="totalLessonNum"
              min-width="150px"
            />
            <el-table-column
              label="结业人数"
              align="left"
              show-overflow-tooltip
              prop="graduationNum"
              min-width="150px"
            />
            <el-table-column
              label="计划培训人数"
              align="left"
              show-overflow-tooltip
              prop="projectPeople"
              min-width="150px"
            />
            <el-table-column
              label="公共课学时"
              align="left"
              show-overflow-tooltip
              prop="publicLessonNum"
              min-width="150px"
            />
            <el-table-column
              label="业务员"
              align="left"
              show-overflow-tooltip
              prop="salesman"
              fixed="right"
              min-width="150px"
            />

            <!-- <Empty slot="empty" /> -->
          </el-table>
        </div>
        <div class="el-div"></div>
        <h3 style="padding-bottom: 20px">流转记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="ListData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              :index="indexMethod"
              width="100px"
            />
            <el-table-column
              label="操作时间"
              align="left"
              show-overflow-tooltip
              min-width="150"
            >
              <template slot-scope="scope">{{
                scope.row.createTime | moment
              }}</template>
            </el-table-column>
            <el-table-column
              label="操作人"
              align="left"
              show-overflow-tooltip
              prop="fullname"
              min-width="100"
            />
            <el-table-column
              label="操作内容"
              align="left"
              show-overflow-tooltip
              min-width="100"
            >
              <template slot-scope="scope">
                {{
                  $setDictionary(
                    "FD_NEW_AUDIT_TYPE_RECORD",
                    scope.row.auditType
                  ) == "确认收款"
                    ? "确认付款"
                    : $setDictionary(
                        "FD_NEW_AUDIT_TYPE_RECORD",
                        scope.row.auditType
                      )
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="left"
              show-overflow-tooltip
              prop="remark"
              min-width="150"
            />
          </el-table>
        </div>
        <div class="el-div"></div>
        <div>
          <h3 style="margin: 30px 0 20px">备注</h3>
          <el-input
            type="textarea"
            placeholder="如果驳回请填写理由,不超过100字"
            v-model="auditReason"
            maxlength="100"
            show-word-limit
          ></el-input>
        </div>
        <div class="flexcc" style="padding: 2rem">
          <el-button
            class="bgc-bv"
            style="margin: 0 10px"
            @click="$router.back()"
            >取消</el-button
          >
          <el-button class="bgc-bv" style="margin: 0 10px" @click="reject('10')"
            >驳回</el-button
          >
          <el-button class="bgc-bv" style="margin: 0 10px" @click="reject('20')"
            >审核通过</el-button
          >
          <el-button
            class="bgc-bv"
            style="margin: 0 10px"
            v-show="stu == 'payment'"
            @click="exportClass()"
            >导出班级列表</el-button
          >
        </div>
      </div>
    </div>
     <el-dialog
        class="dialog2"
        title="预览附件"
        :visible.sync="dialog1"
        width="50%"
        top="15px"
        center
        ><div id="pdf-content" style="height: 500px"></div>
      </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "evaluate/statementManagementFinanceToReview",
  components: {},
  mixins: [List],
  data() {
    return {
      pages: 0, //pdf分页
      pdfDoc: null,
      billId: this.$route.query.billId,
      ListData: [],
      ListData2: [],
      payerAmountSum: "",
      settlementUserCountSum: "",
      searchData: {
        auditType: "",
        compId: "",
        projectCode: "",
        startTime: "",
        invoice: "",
      },
      auditReason: "",
      pdfUrl: "",
      stu: this.$route.query.stu,
      contractId: this.$route.query.contractId,
      annexList:[],
      dialog1:false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getPdf();
    this.getOperation();
    this.getContractIdFile(this.contractId)
    if (this.stu != "agency") {
      this.getOperation2();
    }
  },
  methods: {
    getContractIdFile(contractId) {
      this.$post('/biz/new/bill/annexList',{contractId}).then(res => {
        if (res.status == "0") { 
          this.annexList = res.data || []
         }
        // console.log(res)
      })
    },
    // 预览附件
    lookFile(fileUrl) {
      this.dialog1 = true;
      this.$nextTick(() => {
        pdf.embed(fileUrl, "#pdf-content");
      });
    },
    /* 预览pdf */
    getPdf() {
      this.$post(
        this.$route.query.stu == "collection"
          ? "/biz/new/bill/review"
          : this.$route.query.stu == "agent"
          ? "/biz/new/billAgent/finance/review"
          : this.$route.query.stu == "agency"
          ? "/biz/new/billAgent/finance/review"
          : "/biz/new/bill/pay/review",
        { billId: this.billId,isCa:false }
      )
        .then((ret) => {
          if (
            this.$route.query.stu == "collection" ||
            this.$route.query.stu == "agent" ||
            this.$route.query.stu == "agency"
          ) {
            this.pdfUrl = ret.message;
          } else {
            this.pdfUrl = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    getOperation() {
      this.$post("/biz/new/audit/list", { billId: this.billId })
        .then((ret) => {
          this.ListData = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    getOperation2() {
      this.$post("/biz/new/bill/project/reviewPage", { billId: this.billId })
        .then((ret) => {
          this.ListData2 = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    /* 驳回&&通过 */
    reject(auditStatus) {
      let params = {
        billId: this.billId,
        auditStatus,
      };
      if (auditStatus == "10") {
        if (this.auditReason == "") {
          this.$message({
            message: "请输入驳回理由",
            type: "warning",
          });
          return false;
        } else {
          if (this.auditReason) {
            params.remark = this.auditReason;
          }
        }
      }
      this.$post("/biz/new/audit/submitFinancialReview", params)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$router.push({
              path: "/web/financialSettlement/statementManagementFinance",
              query: {
                active: this.$route.query.name,
                refresh: true,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    //导出班级列表
    exportClass() {
      let params = {
        payBillNo: this.$route.query.billNo,
      };
      this.$post("/biz/new/bill/project/export", params)
        .then((res) => {
          if (res.status == "0") {
            if (!this.downloadItems.includes(res.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", res.data.taskId);
            } else {
              this.$message.warning(
                "[" + res.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(re.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.el-div{
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.classCheckDetail {
  // th{
  //   background-color: #ffffff !important;
  //   color:#000 !important;
  //   border-top: 1px solid rgb(92, 107, 232);
  // }
  .btn-box {
    text-align: center;
    margin-top: 30px;
  }
  .el_form {
    .form-inline {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }

  .baseSet {
    span {
      margin: 20px 0 20px;
    }
  }
  .framePage-body {
    width: 100%;
    overflow-y: auto;
  }
  .el-div {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    border-bottom: 1px solid #dcdfe6;
  }
  .el-textarea {
    .el-textarea__inner {
      min-height: 100px !important;
      resize: none;
    }
  }
}
</style>

